import BlockContent from "@sanity/block-content-to-react"
import {
  addDays,
  compareAsc,
  eachDayOfInterval,
  formatISO,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
} from "date-fns"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React, { FC, useContext, useEffect, useState } from "react"
import DayPicker, { DayModifiers } from "react-day-picker"
import "react-day-picker/lib/style.css"
import ReactModal from "react-modal"
import { formatCurrencyString, useShoppingCart } from "use-shopping-cart"
import AppContext from "../AppContext"
import { BetalKnap } from "../components/bestilling/bestil-kurv"
import Button from "../components/button"
import DualPaneImage from "../components/dual-pane-image"
import Header from "../components/header"
import Layout from "../components/layout"
import LeftOverlay from "../components/left-overlay"
import theme from "../css/tailwindTheme.json"
import { blockSerializer } from "../utils/sanity-helper"
import { dataset, projectId } from "../utils/sanityConfig"
import { getFluidImage } from "../utils/sanityImage"
import { Counts } from "../utils/utils"

export const query = graphql`
  query {
    faerdigretter: sanityFaerdigretterPage {
      id
      _rawBody
      _rawMainImage
      mainImage {
        asset {
          fluid(maxWidth: 1152, maxHeight: 420) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }

    senestemenu: allSanityMenu(
      sort: { fields: [aar, uge], order: [DESC, DESC] }
      limit: 1
    ) {
      nodes {
        uge
        aar
      }
    }
    retter: allSanityFaerdigretter(
      filter: { active: { eq: true } }
      sort: { order: ASC, fields: placement }
    ) {
      nodes {
        _id
        price
        title
        minAmount
        no_order_dates
        invert_dates
        start_date
        end_date
        orderable
        _rawDescription
        _rawDeliveryInfo
        _rawMainImage
        mainImage {
          asset {
            fluid(maxWidth: 576, maxHeight: 330) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
    }
  }
`

const WEEKDAYS_SHORT = {
  da: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
}

const WEEKDAYS_LONG = {
  da: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
}

const MONTHS = {
  da: [
    "Januar",
    "Februar",
    "Marts",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "December",
  ],
}

const FIRST_DAY_OF_WEEK = {
  da: 1,
}

const LABELS = {
  da: { nextMonth: "Næste måned", previousMonth: "Sidste måned" },
}

const locale = "da"
const Faerdigretter: FC<any> = (props) => {
  const aar = props.data.senestemenu.nodes[0].aar
  const uge = props.data.senestemenu.nodes[0].uge
  const retter = props.data.retter.nodes
  const [menuCounts, setMenuCounts] = useState<Counts[]>([])
  const [selectedDay, setSelectedDay] = useState<Date | undefined>()
  const [ordering, setOrdering] = useState("")
  const [orderingName, setOrderingName] = useState("")
  const [orderAmount, setOrderAmount] = useState(0)
  const [price, setPrice] = useState(0)
  const [telefon, setTelefon] = useState("")
  const [kommentar, setKommentar] = useState("")
  const { loading, setLoading } = useContext(AppContext)
  const { redirectToCheckout } = useShoppingCart()

  useEffect(() => {
    const ids = retter.map((menu: any) => menu._id)
    fetch(`/.netlify/functions/sanity-helper`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ids),
    })
      .then((res) => {
        return res.json()
      })
      .then((counts: { id: string; count: number | undefined }[]) => {
        setMenuCounts(counts)
      })
  }, [retter, setLoading])

  const disallowedDates = (
    invert_dates: boolean,
    no_order_dates?: string[],
    start_date?: string,
    end_date?: string
  ) => {
    const today = new Date()
    const twoDaysFromNow = addDays(today, 2)
    const startDate = start_date ? parseISO(start_date) : today
    let orderCutoffDate = isAfter(startDate, twoDaysFromNow)
      ? startDate
      : twoDaysFromNow

    const endDate = end_date ? parseISO(end_date) : addDays(today, 90)

    const rawDates = (no_order_dates ?? []).map((date) => parseISO(date))
    rawDates.sort(compareAsc)

    let noOrderDates = rawDates.filter(
      (date) =>
        isSameDay(date, orderCutoffDate) || isAfter(date, orderCutoffDate)
    )

    if (rawDates.length > 0 && invert_dates) {
      if (isBefore(rawDates[0], orderCutoffDate)) orderCutoffDate = rawDates[0]

      const range = eachDayOfInterval({
        start: today,
        end: endDate,
      }).filter(
        (day) =>
          !rawDates.some((onlyDay) => {
            if (isAfter(today, onlyDay)) return false
            return isSameDay(day, onlyDay)
          })
      )
      noOrderDates = range
    }

    if (isAfter(today, orderCutoffDate)) orderCutoffDate = today

    return [
      ...noOrderDates,
      {
        after: endDate,
        before: orderCutoffDate,
      },
    ]
  }

  const handleDayClick = (day: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return
    }
    setSelectedDay(modifiers.selected ? undefined : day)
  }

  const stopOrdering = () => {
    setSelectedDay(undefined)
    setOrdering("")
    setOrderingName("")
    setOrderAmount(0)
  }

  const startBetaling = async () => {
    if (!selectedDay) return

    setLoading(true)

    // const sortedCartItems = sortCartItemsByDate(cartDetails)
    const line_items = [
      {
        name: `${orderAmount} x ${orderingName}`,
        amount: orderAmount * price * 0.8,
        currency: "DKK",
        quantity: 1,
        description: `${orderAmount} x ${orderingName} til afhentning ${formatISO(
          selectedDay,
          {
            representation: "date",
          }
        )}`,
      },
    ]

    const orderData = {
      telefon,
      kommentar,
      line_items,
      id: ordering,
      count: orderAmount,
    }

    const response = await fetch(
      "/.netlify/functions/create-catering-session",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderData),
      }
    )
      .then((res) => {
        return res.json()
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
      .finally(() => {
        stopOrdering()
      })

    redirectToCheckout({ sessionId: response.sessionId })
  }

  const getCount = (_id: string): number | undefined => {
    const count = menuCounts.find((count) => count.id === _id)
    if (count && count.count !== undefined) {
      return count.count
    } else {
      return undefined
    }
  }

  const soldOut = (_id: string) => {
    const count = getCount(_id)
    if (count !== undefined) {
      return count <= 0
    } else return false
  }

  return (
    <Layout title="Færdigretter" aar={aar} uge={uge}>
      <LeftOverlay title="Færdigretter">
        <Img
          backgroundColor
          fluid={getFluidImage(
            props.data.faerdigretter._rawMainImage,
            props.data.faerdigretter.mainImage.asset.fluid,
            1152,
            420
          )}
        />
      </LeftOverlay>

      <BlockContent
        blocks={props.data.faerdigretter._rawBody}
        serializers={blockSerializer()(
          <div className="px-8 mt-4 text-xl text-center max-w-3xl mx-auto" />,
          "normal"
        )}
        projectId={projectId}
        dataset={dataset}
      />

      {retter.map((menu: any, index: number) => (
        <div key={menu._id}>
          <DualPaneImage
            image={getFluidImage(
              menu._rawMainImage,
              menu.mainImage.asset.fluid,
              576,
              330
            )}
            imageRight={index % 2 !== 0}
          >
            <Header>{menu.title}</Header>
            <BlockContent
              blocks={menu._rawDescription}
              serializers={blockSerializer()()}
              projectId={projectId}
              dataset={dataset}
            />
            <div className="pt-4">
              <BlockContent
                blocks={menu._rawDeliveryInfo}
                serializers={blockSerializer()()}
                projectId={projectId}
                dataset={dataset}
              />
            </div>
            <p className="pt-2 font-bold">
              Pris pr. kuvert: {menu.price} kr. inkl. moms
            </p>

            {menu.orderable && !soldOut(menu._id) && (
              <Button
                text="Bestil"
                disabled={!menuCounts.find((count) => count.id === menu._id)}
                onClick={() => {
                  setPrice(menu.price * 100)
                  setOrderingName(menu.title)
                  setOrdering(menu._id)
                }}
              />
            )}
            {menu.orderable && soldOut(menu._id) && (
              <Button text="Udsolgt" disabled />
            )}
            {menu.orderable && !soldOut(menu._id) && (
              <ReactModal
                isOpen={ordering === menu._id}
                onRequestClose={stopOrdering}
                ariaHideApp={false}
                style={{ overlay: { zIndex: 1000 } }}
              >
                <div className="flex flex-col justify-center h-full max-w-4xl mx-auto">
                  <div>
                    <h2>Bestil {menu.title}</h2>
                    <div className="mt-4 -mb-4 text-xs">
                      Leveringsdato{" "}
                      {selectedDay
                        ? formatISO(selectedDay, {
                            representation: "date",
                          })
                        : "ikke valgt"}
                    </div>
                    <DayPicker
                      showWeekNumbers
                      onDayClick={handleDayClick}
                      disabledDays={disallowedDates(
                        menu.invert_dates,
                        menu.no_order_dates,
                        menu.start_date,
                        menu.end_date
                      )}
                      locale={locale}
                      months={MONTHS[locale]}
                      weekdaysLong={WEEKDAYS_LONG[locale]}
                      weekdaysShort={WEEKDAYS_SHORT[locale]}
                      firstDayOfWeek={FIRST_DAY_OF_WEEK[locale]}
                      labels={LABELS[locale]}
                    />

                    <div className="grid grid-cols-2 my-8">
                      <div>
                        <div className="text-xs">Telefonnummer</div>
                        <input
                          className="p-2"
                          type="text"
                          placeholder="Skriv telefonnummer her"
                          value={telefon}
                          onChange={(e) => setTelefon(e.target.value)}
                        />
                      </div>
                      <div>
                        <div className="w-full text-xs">Kommentar</div>
                        <textarea
                          className="p-2"
                          placeholder="Skriv kommentar til ordren her"
                          value={kommentar}
                          onChange={(e) => setKommentar(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <div>
                          <div className="text-xs">
                            Antal kuverter, minimum {menu.minAmount}{" "}
                            {getCount(menu._id)
                              ? `, maximum ${getCount(menu._id)}`
                              : ""}
                          </div>
                          <span className="relative z-0 inline-flex rounded-md">
                            <button
                              type="button"
                              onClick={() =>
                                orderAmount > 0
                                  ? setOrderAmount(orderAmount - 1)
                                  : setOrderAmount(0)
                              }
                              className="relative inline-flex items-center p-2 transition duration-150 ease-in-out bg-opacity-50 border border-gray-300 rounded-l-md hover:text-gray-400 focus:z-10 focus:outline-none active:bg-gray-100 active:text-gray-500"
                              style={{
                                touchAction: "manipulation",
                                backgroundColor:
                                  orderAmount > 0
                                    ? theme.colors.main.blue
                                    : theme.colors.main.smoke,
                                color:
                                  orderAmount > 0
                                    ? theme.colors.main.lightsmoke
                                    : theme.colors.main.gray,
                              }}
                            >
                              <svg
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            <div
                              className="inline-flex items-center justify-center w-12 bg-opacity-50 border border-l-0 font-xl"
                              style={{
                                backgroundColor:
                                  orderAmount > 0
                                    ? theme.colors.main.blue
                                    : theme.colors.main.smoke,
                                color:
                                  orderAmount > 0
                                    ? theme.colors.main.lightsmoke
                                    : theme.colors.main.gray,
                              }}
                            >
                              <pre className="">{orderAmount}</pre>
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                const maxCount = getCount(menu._id)
                                if (maxCount) {
                                  if (orderAmount < maxCount) {
                                    setOrderAmount(orderAmount + 1)
                                  }
                                } else {
                                  setOrderAmount(orderAmount + 1)
                                }
                              }}
                              className="relative inline-flex items-center p-2 -ml-px transition duration-150 ease-in-out bg-opacity-50 border border-gray-300 rounded-r-md hover:text-gray-400 focus:z-10 focus:outline-none active:bg-gray-100 active:text-gray-500"
                              style={{
                                touchAction: "manipulation",
                                backgroundColor:
                                  orderAmount > 0
                                    ? theme.colors.main.blue
                                    : theme.colors.main.smoke,
                                color:
                                  orderAmount > 0
                                    ? theme.colors.main.lightsmoke
                                    : theme.colors.main.gray,
                              }}
                            >
                              <svg
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </span>
                        </div>
                        <div className="flex flex-col justify-end">
                          <div>
                            {orderAmount > 0
                              ? `${orderAmount} x ${
                                  menu.title
                                } ${formatCurrencyString({
                                  value: orderAmount * price,
                                  currency: "DKK",
                                })}`
                              : ""}
                          </div>
                        </div>
                      </div>
                      {orderAmount >= menu.minAmount && telefon.length < 8 && (
                        <div className="mt-2 -mb-2 text-red-600">
                          Husk at skrive dit telefonnummer
                        </div>
                      )}
                      {orderAmount >= menu.minAmount && !selectedDay && (
                        <div className="mt-2 -mb-2 text-red-600">
                          Husk at vælge en leveringsdato
                        </div>
                      )}

                      <BetalKnap
                        disabled={
                          orderAmount < menu.minAmount ||
                          telefon.length < 8 ||
                          !selectedDay
                        }
                        classes="text-xl font-bold py-2 mt-2"
                        loading={loading}
                        onClick={startBetaling}
                      />
                    </div>
                  </div>
                </div>
              </ReactModal>
            )}
          </DualPaneImage>
        </div>
      ))}

      {/* <div className="mt-16">
        <BestilDagensRet aar={aar} uge={uge} />
      </div> */}
    </Layout>
  )
}

export default Faerdigretter
